<template>
    <div v-if="!loading">
        <AssignAdminModal ref="assignModal" @admin:selected="addAdmin" />

        <b-tabs v-model="activeTab">
            <b-tab-item label="Commande / Précommande">
                <div class="columns">
                    <div class="column mx-4">
                        <ConfigMailRow config-key="mails_demande_retrait_gains" @modal:show="showModal" />
                    </div>
                    <div class="column mx-4">
                        <ConfigMailRow config-key="mails_nouveau_site_partenaire" @modal:show="showModal" />
                    </div>
                    <div class="column mx-4">
                        <ConfigMailRow config-key="mails_acceptation_commande" @modal:show="showModal" />
                    </div>
                </div>
            </b-tab-item>

            <b-tab-item label="Partenaire">
                <div class="columns">
                    <div class="column mx-4">
                        <ConfigMailRow config-key="mails_nouveau_site_partenaire" @modal:show="showModal" />
                    </div>
                    <div class="column mx-4">
                        <ConfigMailRow config-key="mails_delai_publication_depasse" @modal:show="showModal" />
                    </div>
                    <div class="column mx-4">
                        <ConfigMailRow config-key="admin_bulk_notif_delete" @modal:show="showModal" />
                    </div>
                    <div class="column mx-4">
                        <ConfigMailRow config-key="admin_bulk_notif_desactivate" @modal:show="showModal" />
                    </div>
                </div>
            </b-tab-item>

            <b-tab-item label="Accompagnement">
                <div class="columns">
                    <div class="column mx-4">
                        <ConfigMailRow config-key="mails_demande_accompagnement" @modal:show="showModal" />
                    </div>
                    <div class="column mx-4">
                        <ConfigMailRow config-key="mails_refus_commande_accompagnement" @modal:show="showModal" />
                    </div>
                </div>
            </b-tab-item>

            <b-tab-item label="Rédacteurs">
                <div class="columns">
                    <div class="column mx-4">
                        <ConfigMailRow config-key="redactor_corrector_ids" @modal:show="showModal" />
                    </div>
                    <div class="column mx-4">
                        <ConfigMailRow config-key="redactor_admins_ids" @modal:show="showModal" />
                    </div>
                </div>
            </b-tab-item>

            <b-tab-item label="Autre">
                <div class="columns">
                    <div class="column mx-4">
                        <ConfigMailRow config-key="mails_demande_suppression_compte" @modal:show="showModal" />
                    </div>
                    <div class="column mx-4">
                        <ConfigMailRow config-key="email_notifications_attestations" @modal:show="showModal" />
                    </div>
                    <div class="column mx-4">
                        <ConfigMailRow config-key="management_nl_ids" @modal:show="showModal" />
                    </div>
                </div>
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<style scoped>
    .column {
        margin-left: 0.5em;
        margin-right: 0.5em;
        padding: 1em;
        background-color: #212529;
        border-radius: 25px;
    }

    .column .columns {
        margin-right: 0.5em;
    }
</style>

<script>
    import { mapActions, mapState } from 'vuex'
    import AssignAdminModal from '@/components/Config/AssignAdminsModal'
    import ConfigMailRow from '@/components/Config/ConfigMailRow'

    export default {
        name: 'ConfigMail',
        title: 'Config Mail',
        components: {
            ConfigMailRow,
            AssignAdminModal
        },
        data() {
            return {
                activeTab: 0,
                loading: true,
                columnIndex: 0,
                callbackFunction: null,
                map: {}
            }
        },
        computed: {
            ...mapState('config', {
                configEmail: state => state.configEmail,
                admins: state => state.admins
            })
        },
        created() {
            this.getEmailConfig()
                .catch(e => {
                    this.$store.dispatch('toastError', e)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        methods: {
            ...mapActions('config', ['getEmailConfig', 'saveEmailConfig']),
            showModal(data) {
                this.$refs.assignModal.show()
                this.columnName = data.key
                this.callbackFunction = data.callback
            },
            addAdmin(admin) {
                this.$refs.assignModal.hide()
                this.configEmail[this.columnName].push(admin)

                let object = {}
                object[this.columnName] = []

                this.configEmail[this.columnName].forEach(user => {
                    object[this.columnName].push(user.id)
                })

                object[this.columnName] = '[' + object[this.columnName].join() + ']'

                this.saveEmailConfig(object)
                    .then(() => {
                        this.callbackFunction()
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loader = false
                    })
            }
        }
    }
</script>
